import { http } from '@/lib/http'
import { RegisterParamsType, CompleteRegisterParamsType } from '#/UserTypes'

export default {
  /**
   * 获取验证码
   * @param {string} email
   * @returns
   */
  sendCaptcha: (email: string) => {
    return http.request('post', '/captcha', { data: { email } })
  },

  /**
   * 用户登录
   * @param {string} email
   * @param {number} [captchaCode]
   * @param {string} [password]
   * @returns
   */
  userLogin: (email: string, captchaCode?: number, password?: string) => {
    if (password) {
      return http.request('post', '/user/login', { data: { email, code: null, password } })
    }
    return http.request('post', '/user/login', { data: { email, code: captchaCode, password: null } })
  },

  /**
   * 注册
   * @param {object} data
   * @property {string} data.email 邮箱
   * @property {string} data.name 用户名
   * @property {string} data.password 密码
   * @property {string} [data.referer] 来源
   * @property {number} data.code 验证码
   * @param {string} history // 跳转地址
   * @returns
   */
  userRegister: (data: RegisterParamsType, history: string) => {
    return http.request('post', '/user/register', { data, headers: { 'elven-last-history': history } })
  },

  /**
   * 获取用户登录情况
   * @returns
   */
  getUser: () => {
    return http.request('get', '/user')
  },
  /**
   * 完善用户注册
   * @param {object} data
   * @property {string} data.name 用户名
   * @property {string} data.password 密码
   * @property {string} [data.referer] 来源
   * @param {string} history // 跳转地址
   */
  userCompleteRegister: (data: CompleteRegisterParamsType, history: string) => {
    return http.request('post', '/user/completeRegister', { data, headers: { 'elven-last-history': history } })
  },
  /**
   * google登录
   * @param {object} data
   * @property {string} data.googleToken google登录token
   */
  googleLogin: (data: { googleToken: string }) => {
    return http.request('post', '/user/google/login', { data })
  },
  /**
   * 用户导航排序
   * @param {object} data
   * @property {Array} data.navigationSort 导航排序id
   */
  userNavigationSort: (data: any) => {
    return http.request('post', '/user/navigationSort', { data: { navigationSort: data } })
  },
  /**
   * 保存用户账户设置
   * @param {object} data
   * @property {string} data.language 语言ZH_CN EN_US
   */
  saveUserAccountSetting: (data: object) => {
    return http.request('put', '/user/accountSetting', { data })
  },
  /**
   * 生成二次验证信息
   */
  userTwoFactorAuthGenerate: () => {
    return http.request('post', '/user/twoFactorAuth/generate')
  },
  /**
   * 开启二次验证
   * @param {object} data
   * @property {string} data.authCode 二次验证code
   */
  userTwoFactorAuthEnable: (data: object) => {
    return http.request('post', '/user/twoFactorAuth/enable', { data })
  },
  /**
   * 关闭二次验证
   * @param {object} data
   * @property {string} data.password 密码
   * @property {string} data.authCode 二次验证code
   */
  userTwoFactorAuthDisable: (data: object) => {
    return http.request('post', '/user/twoFactorAuth/disable', { data })
  },
  /**
   * 重新生成backupCodes
   * @param {object} data
   * @property {string} data.password 密码
   * @property {string} data.authCode 二次验证code
   */
  userTwoFactorAuthRegenerateBackupCodes: (data: object) => {
    return http.request('post', '/user/twoFactorAuth/backupCodes/regenerate', { data })
  },
  /**
   * 修改密码
   * @param {object} data
   * @property {string} data.oldPassword 旧密码
   * @property {string} data.newPassword 新密码
   * @property {string} [data.authCode]
   */
  editUserPassword: (data: object) => {
    return http.request('put', '/user/password', { data })
  },
  /**
   * 二次验证
   * @param {string} token
   * @param {object} data
   * @property {string} data.authCode 二次验证码
   */
  userTwoFactorAuthVerify: (token: string, data: object) => {
    return http.request('post', '/user/twoFactorAuth/validate', { headers: { Authorization: `Bearer ${token}` }, data })
  }
}
