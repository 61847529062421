import type { App, Component } from 'vue'

import SvgIcon from './Base/SvgIcon.vue'
import ElvButton from './Base/ElvButton.vue'
import ElvSelect from './Base/ElvSelect.vue'
import ElvMessageBox from './Base/ElvMessageBox.vue'
import ElvPagination from './Base/ElvPagination/index.vue'

const components: {
  [name: string]: Component
} = {
  SvgIcon,
  ElvButton,
  ElvSelect,
  ElvPagination,
  ElvMessageBox
}

export default {
  install(app: App) {
    // 注册全局的组件 （对所有需要注册的组件进行遍历并注册）
    Object.keys(components).forEach((key: string) => {
      app.component(key, components[key])
    })
  }
}
