import { http } from '@/lib/http'
import {
  ChartOfAccountListType,
  ChartOfAccountExportThirdpartyType,
  ChartOfAccountImportMappingGroupType,
  ChartOfAccountThirdpartyPlatformType,
  ChartOfAccountImportMappingGroupParamsType
} from '#/LedgerTypes'
import { JournalDetailType, JournalGroupListType, JournalListType } from '#/TransactionsTypes'

export default {
  /**
   * 获取ChartOfAccount列表
   * @param {string} entityId 主体id
   * @param {Object} [params]
   * @param {string} [params.thirdpartyPlatform] 第三方平台
   */
  getChartOfAccountList: (entityId: string, params?: object) => {
    return http.request<ResponseData<ChartOfAccountListType[]>>('get', `/entity/${entityId}/chartOfAccount`, { params })
  },
  /**
   * 创建ChartOfAccount
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.parentChartOfAccountId 父级科目id
   * @property {string} data.code 科目代码
   * @property {string} data.name 科目名称
   * @property {string} [data.balanceType] 余额类型
   * @property {string} [data.auxiliaryCodeList] 辅助核算
   * @property {string} [data.enableOriginalCurrency] 是否启用原币
   * @property {string} [data.isCashAccount] 是否现金科目
   * @property {string} [data.description] 描述
   */
  createChartOfAccount: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/chartOfAccount`, { data })
  },
  /**
   * 获取ChartOfAccount详情
   * @param {string} entityId 主体id
   * @param {string} chartOfAccountId 科目id
   */
  getChartOfAccountDetail: (entityId: string, chartOfAccountId: string) => {
    return http.request('get', `/entity/${entityId}/chartOfAccount/${chartOfAccountId}`)
  },
  /**
   * 修改ChartOfAccount
   * @param {string} entityId 主体id
   * @param {string} chartOfAccountId 科目id
   * @param {Object} data
   * @property {string} data.parentChartOfAccountId 父级科目id
   * @property {string} data.code 科目代码
   * @property {string} data.name 科目名称
   * @property {string} [data.balanceType] 余额类型
   * @property {string} [data.auxiliaryCodeList] 辅助核算
   * @property {string} [data.enableOriginalCurrency] 是否启用原币
   * @property {string} [data.isCashAccount] 是否现金科目
   * @property {string} [data.description] 描述
   */
  editChartOfAccount: (entityId: string, chartOfAccountId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/chartOfAccount/${chartOfAccountId}`, { data })
  },
  /**
   * 检查ChartOfAccount使用情况
   * @param {string} entityId 主体id
   * @param {string} chartOfAccountId 科目id
   */
  checkChartOfAccountUsage: (entityId: string, chartOfAccountId: string) => {
    return http.request('post', `/entity/${entityId}/chartOfAccount/${chartOfAccountId}/checkUsage`)
  },
  /**
   * 删除ChartOfAccount
   * @param {string} entityId 主体id
   * @param {string} chartOfAccountId 科目id
   */
  deleteChartOfAccount: (entityId: string, chartOfAccountId: string) => {
    return http.request('delete', `/entity/${entityId}/chartOfAccount/${chartOfAccountId}`)
  },
  /**
   * 获取推理科目映射列表
   * @param {string} entityId 主体id
   */
  getTreasuryAccountMappingList: (entityId: string) => {
    return http.request('get', `/entity/${entityId}/treasuryAccountMapping`)
  },
  /**
   * 修改推理科目映射
   * @param {string} entityId 主体id
   * @param {Array} data
   */
  editTreasuryAccountMapping: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/treasuryAccountMapping`, { data })
  },
  /**
   * 查看baseBalance详情
   * @param {string} entityId 主体id
   */
  getBaseBalanceDetail: (entityId: string) => {
    return http.request('get', `/entity/${entityId}/baseBalance`)
  },
  /**
   * 创建baseBalance
   * @param {string} entityId 主体id
   * @param {Object} data
   */
  createBaseBalance: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/baseBalance`, { data })
  },
  /**
   * 编辑baseBalance
   * @param {string} entityId 主体id
   * @param {Object} data
   */
  editBaseBalance: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/baseBalance`, { data })
  },
  /**
   * 获取GeneralLedger框架
   * @param {string} entityId 主体id
   * @param {Object} params 查询参数
   * @property {string} params.periodBegin 期间开始
   * @property {string} params.periodEnd 期间结束
   */
  getGeneralLedgerSummary: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/generalLedger/summary`, { params })
  },
  /**
   * 获取GeneralLedger列表
   * @param {string} entityId 主体id
   * @param {Object} params 查询参数
   * @param {string} params.chartOfAccountId 科目id
   * @property {number} params.page 页码
   * @property {string} params.periodBegin 期间开始
   * @property {string} params.periodEnd 期间结束
   */
  getGeneralLedgerList: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/generalLedger/list`, { params })
  },
  /**
   * 获取Account Balance框架
   * @param {string} entityId 主体id
   * @param {Object} params 查询参数
   * @property {string} params.periodBegin 期间开始
   * @property {string} params.periodEnd 期间结束
   */
  getSubsidiaryLedgerSummary: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/subsidiaryLedger/summary`, { data })
  },
  /**
   * 获取Account Balance列表
   * @param {string} entityId 主体id
   * @param {Object} params 查询参数
   * @property {string} params.chartOfAccountId 科目id
   * @property {string} [params.entityAccountId] 实体id
   * @property {string} [params.counterpartyId] 对方id
   * @property {string} params.periodBegin 期间开始
   * @property {string} params.periodEnd 期间结束
   */
  getSubsidiaryLedgerList: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/subsidiaryLedger/list`, { data })
  },
  /**
   * 创建Journal Type
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name 名称
   * @property {string} data.code 代码
   * @property {string} data.journalNoPrefix 单号前缀
   * @property {string} data.group 分组
   * @property {Array} data.entryLines
   */
  createJournalType: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journalType`, { data })
  },
  /**
   * 编辑Journal Type
   * @param {string} entityId 主体id
   * @param {string} journalTypeId Journal Type id
   * @param {Object} data
   * @property {string} data.name 名称
   * @property {string} data.code 代码
   * @property {string} data.journalNoPrefix 单号前缀
   * @property {string} data.group 分组
   * @property {Array} data.entryLines
   */
  editJournalType: (entityId: string, journalTypeId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/journalType/${journalTypeId}`, { data })
  },
  /**
   * 获取Journal Type详情
   * @param {string} entityId
   * @param {string} journalTypeId
   */
  getJournalTypeDetail: (entityId: string, journalTypeId: string) => {
    return http.request('get', `/entity/${entityId}/journalType/${journalTypeId}`)
  },
  /**
   * 删除Journal Type
   * @param {string} entityId
   * @param {string} journalTypeId
   */
  deleteJournalType: (entityId: string, journalTypeId: string) => {
    return http.request('delete', `/entity/${entityId}/journalType/${journalTypeId}`)
  },
  /**
   * 检查JournalType使用状态
   * @param {string} entityId
   * @param {string} journalTypeId
   */
  checkJournalTypeUsage: (entityId: string, journalTypeId: string) => {
    return http.request('post', `/entity/${entityId}/journalType/${journalTypeId}/checkUsage`)
  },
  /**
   * 获取辅助核算字段列表
   * @param {string} entityId
   * @param {Object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getAuxiliaryTypeList: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/auxiliaryType`, { params })
  },
  /**
   * 创建辅助核算字段
   * @param {string} entityId
   * @param {Object} data
   * @property {string} data.name 名称
   * @property {string} data.type 类型
   */
  createAuxiliaryType: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/auxiliaryType`, { data })
  },
  /**
   * 编辑辅助核算字段
   * @param {string} entityId
   * @param {string} auxiliaryTypeId 辅助核算id
   * @param {Object} data
   * @property {string} data.name 名称
   */
  editAuxiliaryType: (entityId: string, auxiliaryTypeId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/auxiliaryType/${auxiliaryTypeId}`, { data })
  },
  /**
   * 删除辅助核算字段
   * @param {string} entityId
   * @param {string} auxiliaryTypeId
   */
  deleteAuxiliaryType: (entityId: string, auxiliaryTypeId: string) => {
    return http.request('delete', `/entity/${entityId}/auxiliaryType/${auxiliaryTypeId}`)
  },
  /**
   * 获取第三方COA的平台文件
   * @param {string} entityId
   */
  getThirdpartyChartOfAccountPlatformMappingList: (entityId: string) => {
    return http.request<ResponseData<ChartOfAccountThirdpartyPlatformType[]>>(
      'get',
      `/entity/${entityId}/chartOfAccount/thirdparty/platform/files`
    )
  },
  /**
   * 获取第三方COA的平台文件
   * @param {string} entityId
   * @param {string} thirdpartyPlatform 第三方平台
   */
  getThirdpartyChartOfAccountList: (entityId: string, thirdpartyPlatform: string) => {
    return http.request<ResponseData<ChartOfAccountExportThirdpartyType[]>>(
      'get',
      `/entity/${entityId}/chartOfAccount/thirdparty/${thirdpartyPlatform}`
    )
  },
  /**
   * 修改第三方COA的平台文件
   * @param {string} entityId
   * @param {string} thirdpartyPlatform 第三方平台
   * @param {Object} data
   */
  editThirdpartyChartOfAccountMapping: (entityId: string, thirdpartyPlatform: string, data: object) => {
    return http.request('put', `/entity/${entityId}/chartOfAccount/thirdparty/${thirdpartyPlatform}/mapping`, {
      data
    })
  },
  /**
   * 第三方COA的csv文件关联
   * @param {string} entityId
   * @param {string} thirdpartyPlatform 第三方平台
   * @param {Object} data
   * @property {string} data.entityFileId 文件id
   */
  relateThirdpartyChartOfAccountCsv: (entityId: string, thirdpartyPlatform: string, data: object) => {
    return http.request('post', `/entity/${entityId}/chartOfAccount/thirdparty/${thirdpartyPlatform}/relation`, {
      data
    })
  },
  /**
   * 获取ChartOfAccount导入映射列表
   * @param {string} entityId
   */
  getChartOfAccountImportMappingList: (entityId: string) => {
    return http.request<ResponseData<ChartOfAccountImportMappingGroupType[]>>(
      'get',
      `/entity/${entityId}/chartOfAccount/importMapping`
    )
  },
  /**
   * 获取ChartOfAccount导入映射预览
   * @param {string} entityId
   * @param {Object} data
   * @property {string} data.entityFileId
   */
  getChartOfAccountImportMappingPreview: (entityId: string, data: object) => {
    return http.request<ResponseData<string[]>>('post', `/entity/${entityId}/chartOfAccount/importMapping/preview`, {
      data
    })
  },
  /**
   * 创建ChartOfAccount导入映射
   * @param {string} entityId
   * @param {object} data
   * @property {string} data.entityFileId
   * @property {string} data.name
   * @property {Array} data.chartOfAccountImportMapping
   * @property {string} data.chartOfAccountImportMapping.item.chartOfAccountId
   * @property {string} data.chartOfAccountImportMapping.item.thirdpartyAccountName
   */
  createChartOfAccountImportMapping: (entityId: string, data: ChartOfAccountImportMappingGroupParamsType) => {
    return http.request('post', `/entity/${entityId}/chartOfAccount/importMapping`, { data })
  },
  /**
   * 编辑ChartOfAccount导入映射
   * @param {string} entityId
   * @param {string} chartOfAccountImportMappingGroupId
   * @param {Object} data
   * @property {string} data.name
   * @property {Array} data.chartOfAccountImportMapping
   * @property {string} data.chartOfAccountImportMapping.item.chartOfAccountId
   * @property {string} data.chartOfAccountImportMapping.item.thirdpartyAccountName
   */
  editChartOfAccountImportMapping: (
    entityId: string,
    chartOfAccountImportMappingGroupId: string,
    data: ChartOfAccountImportMappingGroupParamsType
  ) => {
    return http.request(
      'put',
      `/entity/${entityId}/chartOfAccount/importMapping/${chartOfAccountImportMappingGroupId}`,
      { data }
    )
  },
  /**
   * 删除ChartOfAccount导入映射
   * @param {string} entityId
   * @param {string} chartOfAccountImportMappingGroupId
   */
  deleteChartOfAccountImportMapping: (entityId: string, chartOfAccountImportMappingGroupId: string) => {
    return http.request(
      'delete',
      `/entity/${entityId}/chartOfAccount/importMapping/${chartOfAccountImportMappingGroupId}`
    )
  },
  /**
   * 获取Journal列表
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   * @property {string} data.sortField 排序字段
   * @property {string} data.sortDirection 排序方向
   */
  getJournalList: (entityId: string, data: object) => {
    return http.request<ResponseData<JournalListType>>('post', `/entity/${entityId}/journal/list`, { data })
  },
  /**
   * 创建Journal列表
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.journalTypeId 交易类型id
   * @property {string[]} data.transactionIdPairs 交易id
   * @property {string} [data.timezone] 时区
   * @property {string} [data.datetimeStr] 交易时间
   * @property {string} [data.referenceNo] 参考号
   * @property {string} [data.journalTypeGroup] 交易分组
   * @property {string} [data.memo] 备注
   * @property {Array} [data.entryLines] 分录
   * @property {string[]} [data.journalAttachmentIds] 附件ids
   */
  createJournal: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journal`, { data })
  },
  /**
   * 编辑Journal列表
   * @param {string} entityId 主体id
   * @param {string} journalActivityId 交易id
   * @param {object} data
   * @property {string} data.journalTypeId 交易类型id
   * @property {string[]} data.transactionIdPairs 交易id
   * @property {string} [data.timezone] 时区
   * @property {string} [data.datetimeStr] 交易时间
   * @property {string} [data.referenceNo] 参考号
   * @property {string} [data.journalTypeGroup] 交易分组
   * @property {string} [data.memo] 备注
   * @property {Array} [data.entryLines] 分录
   * @property {string[]} [data.journalAttachmentIds] 附件ids
   */
  editJournal: (entityId: string, journalActivityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/journal/${journalActivityId}`, { data })
  },
  /**
   * 获取Journal分组列表
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {number} data.page 页码
   * @property {number} data.limit
   * @property {string} [data.journalGroupConfigId]
   * @property {object} [data.datetime]
   * @property {Array} [data.currency]
   */
  getJournalGroupList: (entityId: string, data: object) => {
    return http.request<ResponseData<JournalGroupListType>>('post', `/entity/${entityId}/journal/group/list`, { data })
  },
  /**
   * 获取Journal详情
   * @param {string} entityId 主体id
   * @param {string} journalActivityId 交易id
   */
  getJournalDetail: (entityId: string, journalActivityId: string) => {
    return http.request<ResponseData<JournalDetailType>>('get', `/entity/${entityId}/journal/${journalActivityId}`)
  },
  /**
   * 修改Journal详情
   * @param {string} entityId 主体id
   * @param {string} journalActivityId
   * @param {object} data
   * @property {string} [data.memo] 备注
   * @property {string[]} [data.journalAttachmentIds] 附件ids
   */
  editJournalActivity: (entityId: string, journalActivityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/journal/${journalActivityId}`, { data })
  },
  /**
   * 上传CSV创建Journal
   * @param {object} data
   * @property {string} data.chartOfAccountImportMappingGroupId 导入映射分组id
   * @property {string} data.template 模板类型 DEFAULT｜XERO
   * @property {string} data.entityFileId 文件id
   * @property {string} data.timezone 时区
   */
  uploadCSVCreateJournal: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/externalJournalSource`, { data })
  },
  /**
   * 导入Journal记录状态关闭
   * @param {string} entityId
   * @param {string} externalJournalCsvUploadId
   */
  closeJournalCsvUploadStatus: (entityId: string, externalJournalCsvUploadId: string) => {
    return http.request(
      'put',
      `/entity/${entityId}/externalJournalSource/externalJournalCsvUpload/${externalJournalCsvUploadId}/disable`
    )
  }
}
