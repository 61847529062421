import { http } from '@/lib/http'
import { CurrencyItemType } from '#/ReportsTypes'
import { ProjectDetailType } from '#/ProjectDetailTypes'
import { EntityDetailType, EntityMemberItemType } from '#/EntityTypes'
import {
  ProjectMemberType,
  UpdateImageFileType,
  ProjectSecurityLogType,
  SubscribeInvoiceItemType,
  ProjectSecurityLogParamsType
} from '#/ProjectTypes'

export default {
  /**
   * 上传图片
   * @param {Object} data
   * @property {file} data.file
   */
  uploadFile: (data: object) => {
    return http.request<ResponseData<UpdateImageFileType>>('post', '/project/upload', { data })
  },
  /**
   * 创建免费项目
   * @param {Object} data
   * @property {string} data.name 项目名称
   * @property {string} data.logo 项目logo
   * @property {string} data.companyName 公司全称
   * @property {string} data.region 项目地区
   * @property {string} data.employees 公司规模
   * @property {string} data.website 公司官方
   * @property {string} data.firstName 名字
   * @property {string} data.lastName 姓氏
   * @property {string} data.jobTitle 职位
   * @property {string} data.email 工作邮箱
   * @property {string} data.plan 项目类型 FREE
   * @property {string} [data.ref] 来源
   * @property {string} data.limitation 工作邮箱校验限制 1有限制 0没有限制
   */
  projectCreateByOpenFree: (data: object) => {
    return http.request('post', '/project/open/free', { data })
  },
  /**
   * 创建项目
   * @param {Object} data
   * @property {string} data.name 项目名称
   * @property {string} data.logo 项目logo
   * @property {string} data.companyName 公司全称
   * @property {string} data.region 项目地区
   * @property {string} data.employees 公司规模
   * @property {string} data.website 公司官方
   * @property {string} data.firstName 名字
   * @property {string} data.lastName 姓氏
   * @property {string} data.jobTitle 职位
   * @property {string} data.email 工作邮箱
   * @property {string} data.plan 项目类型 FREE|BASIC|PRO|ENTERPRISE|VIP
   */
  createNewProject: (data: object) => {
    return http.request('post', '/project', { data })
  },
  /**
   * 激活项目
   * @param {string} projectId
   */
  activateFreeProject: (projectId: string) => {
    return http.request('post', `/project/${projectId}/activate`)
  },
  /**
   * 创建Entity
   * @param {string} projectId 项目id
   * @param {Object} data
   * @property {string} data.name 主体名称
   * @property {string} data.logo 主体logo
   * @property {string} data.fairValueCostBasisMethodology 主体计价方法
   * @property {string} data.region 主体地区
   * @property {string} data.defaultCurrency 主体默认币种
   * @property {string} data.unit 主体单位
   * @property {string} data.creationDate 主体创建时间
   */
  createProjectEntity: (projectId: string, data: object) => {
    return http.request('post', `/project/${projectId}/entity`, { data })
  },
  /**
   * 删除Entity
   * @param {string} entityId 主体id
   */
  deleteProjectEntity: (entityId: string) => {
    return http.request('delete', `/entity/${entityId}`)
  },
  /**
   * 获取entity详情
   * @param {string} entityId 主体id
   */
  getEntityDetail: (entityId: string) => {
    return http.request<ResponseData<EntityDetailType>>('get', `/entity/${entityId}`)
  },
  /**
   * 获取项目列表接口
   */
  getProjectList: () => {
    return http.request<ResponseData<ProjectDetailType[]>>('get', `/project`)
  },
  /**
   * 编辑Entity
   * @param {Object} data
   * @property {string} data.name 主体名称
   * @property {string} data.logo 主体logo
   * @property {string} data.fairValueCostBasisMethodology 主体计价方法
   * @property {string} data.region 主体地区
   * @property {string} data.defaultCurrency 主体默认币种
   * @property {string} data.unit 主体单位
   * @property {string} data.creationDate 主体创建时间
   */
  editEntityDetail: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}`, { data })
  },
  /**
   * 获取entity列表
   * @param {string} projectId
   */
  getEntityList: (projectId: string) => {
    return http.request('get', `/project/${projectId}/entity`)
  },
  /**
   * 获取项目成员列表
   * @param {string} projectId
   */
  getProjectMemberList: (projectId: string) => {
    return http.request<ResponseData<ProjectMemberType[]>>('get', `/project/${projectId}/member`)
  },
  /**
   * 获取entity成员列表
   * @param {string} entityId
   */
  getEntityMemberList: (entityId: string) => {
    return http.request<ResponseData<EntityMemberItemType[]>>('get', `/entity/${entityId}/member`)
  },
  /**
   * 添加项目成员
   * @param {string} projectId
   * @param {Object} data
   */
  addProjectMember: (projectId: string, data: object) => {
    return http.request('post', `/project/${projectId}/member`, { data })
  },
  /**
   * 删除项目成员
   * @param {string} projectId
   * @param {string} userId
   */
  deleteProjectMember: (projectId: string, userId: string) => {
    return http.request('delete', `/project/${projectId}/member/${userId}`)
  },
  /**
   * 编辑项目成员权限
   * @param {string} projectId
   * @param {string} userId
   * @param {Object} data
   */
  editProjectMember: (projectId: string, userId: string, data: object) => {
    return http.request('put', `/project/${projectId}/member/${userId}`, { data })
  },
  /**
   * 获取项目详情
   * @param {string} projectId
   */
  getProjectDetail: (projectId: string) => {
    return http.request<ResponseData<ProjectDetailType>>('get', `/project/${projectId}`)
  },
  /**
   * 修改项目
   * @param {Object} data
   * @property {string} data.name 项目名称
   * @property {string} data.logo 项目logo
   * @property {string} data.companyName 公司全称
   * @property {string} data.region 项目地区
   * @property {string} data.employees 公司规模
   * @property {string} data.website 公司官方
   * @property {string} data.firstName 名字
   * @property {string} data.lastName 姓氏
   * @property {string} data.jobTitle 职位
   * @property {string} data.email 工作邮箱
   */
  editProjectDetail: (projectId: string, data: object) => {
    return http.request('put', `/project/${projectId}`, { data })
  },
  /**
   * 重新发送邀请邮件
   * @param {string} projectId
   * @param {string} userId
   */
  resendInvitationEmail(projectId: string, userId: string) {
    return http.request('post', `/project/${projectId}/member/${userId}/resendInvitation`)
  },
  /**
   * 获取用户操作log日志
   * @param {string} projectId
   * @param {Object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getProjectLog: (projectId: string, params: ProjectSecurityLogParamsType) => {
    return http.request<ResponseData<ProjectSecurityLogType>>('get', `/project/${projectId}/user/log`, { params })
  },
  /**
   * 获取currency列表
   * @param {string} entityId 主体id
   */
  getCurrencyList: (entityId: string) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/entity/${entityId}/currency`)
  },
  /**
   * 获取journal currency列表
   * @param {string} entityId 主体id
   */
  getJournalCurrencyList: (entityId: string) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/entity/${entityId}/journal/currency`)
  },
  /**
   * 获取platform列表
   * @param {string} entityId 主体id
   */
  getPlatformList(entityId: string) {
    return http.request('get', `/entity/${entityId}/platform`)
  },
  /**
   * 导出 Excel数据
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type 导出类型
   * @property {object} data.extra 额外参数
   */
  exportExcelData(entityId: string, data: object) {
    return http.request('post', `/entity/${entityId}/export/excel`, { data })
  },
  /**
   * 发送邮箱
   * @param {string} projectId 项目Id
   */
  sendEmail(projectId: string) {
    return http.request('post', `/project/${projectId}/sendActivateEmail`)
  },

  /**
   * 订阅项目，获取绑定支付方式地址
   * @param {string} projectId 项目Id
   * @param {Object} data
   * @property {string} data.successUrl 订阅成功回调地址
   * @property {string} data.cancelUrl 订阅失败回调地址
   */

  subscribeProject(projectId: string, data: object) {
    return http.request('post', `/project/${projectId}/prepareSubscription`, { data })
  },

  /**
   * 删除项目
   * @param {string} projectId 项目Id
   */
  deleteProject(projectId: string) {
    return http.request('delete', `/project/${projectId}`)
  },

  /**
   * 暂停订阅项目
   */
  pauseSubscriptionProject(projectId: string) {
    return http.request('post', `/project/${projectId}/pauseSubscription`)
  },

  /**
   * 恢复订阅
   */
  resumeSubscriptionProject(projectId: string) {
    return http.request('post', `/project/${projectId}/resumeSubscription`)
  },

  /**
   * 手动支付接口
   * @param {string} projectId 项目Id
   * @param {Object} data
   * @property {string} data.paymentId 支付Id
   */
  paymentGenerate(projectId: string, data: object) {
    return http.request('post', `/project/${projectId}/paymentLink/generate`, { data })
  },

  /**
   * 升级项目
   * @param {string} projectId 项目Id
   */
  upgradeProject(projectId: string) {
    return http.request('post', `/project/${projectId}/upgrade`)
  },

  /**
   * 获取账单列表信息
   * @param {string} projectId 项目Id
   */
  getSubscribeInvoices(projectId: string) {
    return http.request<ResponseData<SubscribeInvoiceItemType[]>>('get', `/project/${projectId}/subscribe/invoices`)
  }
}
