import axios from 'axios'
import { emitter } from '@/lib/mitt'

interface IntervalType {
  timer: number | null
  setInterval: Function
  clearInterval: Function
}

const Interval: IntervalType = {
  timer: null,
  setInterval(callback: Function, interval: number) {
    this.timer = setInterval(callback, interval)
  },
  clearInterval() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }
}

/**
 * 版本检查更新
 */
export const versionCheckUpdate = () => {
  if (process.env.VITE__APP_COMMAND__ === 'serve') return
  // 判断是否与生成的版本信息一致
  const currentVersion = localStorage.getItem('UPDATE_VERSION')
  const systemVersion = String(process.env.VITE__APP_VERSION__)
  if (currentVersion === null) {
    localStorage.setItem('UPDATE_VERSION', systemVersion)
  }
  const newVersion = localStorage.getItem('UPDATE_VERSION')
  if (newVersion && systemVersion !== newVersion) {
    localStorage.setItem('UPDATE_VERSION', systemVersion)
    const timestamp = new Date().getTime()
    // 解析当前 URL 并提取查询参数
    const url = new URL(window.location.href)
    // 设置或更新 nocache 参数
    url.searchParams.set('nocache', String(timestamp))
    // 重新加载页面
    window.location.replace(url.toString())
  }
}

/**
 * 预加载错误重载函数
 */
export function preloadErrorReload() {
  window.addEventListener('vite:preloadError', (event: any) => {
    // 判断是否与生成的版本信息一致
    versionCheckUpdate()
    if (
      event?.message?.includes('Failed to fetch dynamically imported module') ||
      event?.message.includes('Importing a module script failed')
    ) {
      console.error('preloadError--', event)
      // window.location.reload() // 重新加载页面
    }
  })
}

export function getUpdateVersion() {
  if (process.env.VITE__APP_COMMAND__ === 'serve') return
  Interval.setInterval(async () => {
    try {
      const timestamp = new Date().getTime()
      const response = await axios.get(`${window.location.origin}/version.json?nocache=${timestamp}`)
      localStorage.setItem('UPDATE_VERSION', response.data.version)
      emitter.emit('setLocalVersion', response.data.version)
    } catch (error) {
      console.error('Error fetching version:', error)
    }
  }, 1000 * 10)
}

export default getUpdateVersion
