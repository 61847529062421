<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path
      d="M7.875 2.625L7.24937 3.23444L10.5656 6.5625H1.75V7.4375H10.5656L7.24937 10.7507L7.875 11.375L12.25 7L7.875 2.625Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
