import { EntityType, EntityDetailType } from '#/EntityTypes'

export interface UpdateImageFileType {
  path: string
}

export interface ProjectSecurityLogParamsType {
  page: number
  limit: number
  userId?: string
  entityId?: string
  types?: string[]
}

export interface ProjectSecurityLogItemExtraType {
  type: string
  [key: string]: any
}

export interface ProjectSecurityLogItemType {
  client: string
  entity: EntityDetailType
  entityId: string
  params: any
  projectId: string
  projectUserLogId: string
  requestIp: string
  type: string
  extra: ProjectSecurityLogItemExtraType
  user: { userId: string; name: string }
  userId: string
}

export interface ProjectSecurityLogType {
  list: ProjectSecurityLogItemType[]
  total: number
}

export interface ProjectMemberType {
  email: string
  entityList: EntityType[]
  name: string
  updatedAt: string
  userId: string
  role: string
}
export interface ProjectEntityItemType {
  entityId: string
  logo: string
  name: string
  usedSourceCount: number
  usedTxCount: number
}

export interface SubscribeInvoiceItemType {
  invoiceNo: string
  status: string
  amount: number
  date: string
  detailUrl: string
}

/* eslint-disable no-unused-vars */
export enum ProjectPermissionLevel {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
  STANDARD = 'STANDARD',
  VIP = 'VIP',
  OLD_BASIC = 'OLD_BASIC'
}

export enum ProjectReviewStatus {
  UNACTIVATION = 'UNACTIVATION',
  ACTIVATION = 'ACTIVATION',
  PASS = 'PASS',
  WAIT_DATA_UPDATE = 'WAIT_DATA_UPDATE',
  DATA_UPDATE = 'DATA_UPDATE',
  REJECT = 'REJECT',
  STOP = 'STOP'
}

export enum ProjectSubscribeStatus {
  WAIT_PAYMENT = 'WAIT_PAYMENT', // 等待付款
  SUBSCRIBED = 'SUBSCRIBED', // 已订阅
  CANCELED = 'CANCELED', // 已取消
  PAYMENT_FAILED = 'PAYMENT_FAILED', // 扣款失败
  EXCEEDED = 'EXCEEDED', // 已超限
  DELETED = 'DELETED', // 已删除
  EXPIRED = 'EXPIRED', // 已过期
  PAUSED = 'PAUSED' // 已暂停
}
