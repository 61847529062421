<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path
      d="M6.125 11.375L6.74187 10.7581L3.42562 7.4375H12.25V6.5625H3.42562L6.74187 3.24187L6.125 2.625L1.75 7L6.125 11.375Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
