import { i18n } from '@/i18n'
import UserApi from '@/api/UserApi'
import { useCookies } from 'vue3-cookies'
import { UserType, NavigationSortType } from '#/UserTypes'

const { cookies } = useCookies()

export const useUserGlobalStore = defineStore({
  id: 'userGlobal',
  state: () => {
    const userGlobalState: {
      user: UserType | null
      isShowLogin: boolean
      isShowLoginDialog: boolean
      navigationSort: NavigationSortType[]
    } = {
      user: null,
      isShowLogin: false,
      isShowLoginDialog: false,
      navigationSort: []
    }
    return userGlobalState
  },
  getters: {
    isLogin: (state: any) => {
      return Boolean(state.user?.userId)
    }
  },
  actions: {
    // 打开登录弹窗
    loginVisible() {
      this.isShowLogin = true
    },
    // 关闭登录弹窗
    loginHidden() {
      this.isShowLogin = false
    },
    // 打开登录弹窗
    openLogin() {
      this.isShowLoginDialog = true
    },
    // 关闭登录弹窗
    closeLogin() {
      this.isShowLoginDialog = false
    },
    //   用户退出登录
    async logout() {
      window.localStorage.setItem('onlyId', String(this.user?.userId))
      this.user = null
      this.navigationSort = []
      cookies.remove('elv-app-token', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN)
      cookies.remove('elv-app-version', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN)
      this.$reset()
    },
    /** 初始化用户信息 */
    async userInit() {
      // 获取登录用户的信息
      try {
        const { data } = await UserApi.getUser()
        this.user = data
        this.navigationSort = data.navigationSort
        const { locale }: any = i18n.global
        locale.value = data.language === 'ZH_CN' ? 'zh' : 'en'
        localStorage.setItem('language', data.language === 'ZH_CN' ? 'zh' : 'en')
        return true
      } catch (errors: any) {
        console.log(errors)
        if (errors.message === 'Unauthorized' || errors.code === 10000 || errors.code === 401) {
          if (this.user?.userId) {
            window.localStorage.setItem('onlyId', String(this.user?.userId))
          }
          this.user = null
          cookies.remove('elv-app-token', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN)
        }
        return false
      }
    }
  }
})
