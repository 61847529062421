import { ComputedRef } from 'vue'

// eslint-disable-next-line no-unused-vars
export function useComputedHook<T>(fn: (...args: any[]) => T): ComputedRef<T> {
  const cache = new Map<string, any>()

  function getCache(args: any[]): any {
    return cache.get(JSON.stringify(args))
  }

  // eslint-disable-next-line func-names
  const cachedComputed = function (...args: any[]) {
    const cacheKey = JSON.stringify(args)
    const cachedValue = getCache(args)
    if (cachedValue) {
      return cachedValue as T
    }
    const result = computed(() => fn(...args)) as ComputedRef<T>
    cache.set(cacheKey, result)
    return result
  }

  return cachedComputed as unknown as ComputedRef<T>
}
