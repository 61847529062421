import { $t } from '@/i18n/index'
import { find, sortBy } from 'lodash-es'
import { ProjectDetailType } from '#/ProjectDetailTypes'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { HeaderMenuMainDataItemType, NavigationType, MenuItemType } from '#/MenuTypes'

export const useMenuGlobalStore = defineStore({
  id: 'useMenuGlobalStore',
  state: () => {
    const menuGlobalState: {
      menuList: MenuItemType[]
      menuChooseId: string
      menuChooseFatherName: string
      isOpenState: boolean
      isFixedMenu: boolean
      pageNavWidth: number
      mainColor: string
    } = {
      mainColor: '#2d62eb',
      isOpenState: true,
      isFixedMenu: true,
      menuChooseId: '',
      menuChooseFatherName: '',
      pageNavWidth: 100,
      menuList: []
    }
    return menuGlobalState
  },

  actions: {
    /**
     * @description: 修改色值
     * @param {string} params
     * @return {*}
     */
    changeMainColor(params: string) {
      this.mainColor = params
    },

    /**
     * @description: Menu选项改变
     * @param {string} val
     * @return {*}
     */
    changeMenuChooseId(val: string) {
      this.menuChooseId = val
    },

    /**
     * @description: Menu父级选项改变
     * @param {string} val
     * @return {*}
     */
    changeChooseFatherMenuName(val: string) {
      this.menuChooseFatherName = val
    },

    /** 导航改变开关状态
     * @description:
     * @param {boolean} val
     * @return {*}
     */
    changeMenuOpen(val: boolean) {
      this.isOpenState = val
    },

    /**
     * @description: 导航改变固定状态
     * @param {boolean} val
     * @return {*}
     */
    changeMenuFixed(val: boolean) {
      this.isFixedMenu = val
    },

    // 改变页面中nav的宽度
    changePageNavWidth() {
      let leftMenuWidth = 48
      if (this.isFixedMenu) {
        leftMenuWidth = 180
      }
      const nameWidth = document.getElementById('navName')?.offsetWidth ?? 100
      const allWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.pageNavWidth = allWidth - leftMenuWidth - nameWidth - 160
    },
    // 初始化导航数据
    async menuListInit() {
      return new Promise<void>((resolve, reject) => {
        try {
          const userGlobalStore = useUserGlobalStore()
          const globalStore = useGlobalStore()
          const { isLogin, user } = storeToRefs(userGlobalStore)
          let sortedProject: ProjectDetailType[] = []
          // 如果用户有自定义导航，按照自定义导航排序
          if (user.value?.navigationSort?.length) {
            sortedProject = sortBy(globalStore.projectList, (item) => {
              const sortOrderItem = find(user.value?.navigationSort, { projectId: item.projectId })
              return sortOrderItem ? user.value?.navigationSort.indexOf(sortOrderItem) : 999999
            })
            sortedProject.forEach((item) => {
              const sortOrderItem: any = find(user.value?.navigationSort, { projectId: item.projectId })
              if (sortOrderItem) {
                item.entityList = sortBy(item.entityList, (entity) => {
                  const sortOrderEntity = sortOrderItem.entityIds.indexOf(entity.entityId)
                  return sortOrderEntity !== -1 ? sortOrderEntity : 999999
                })
              }
            })
          }
          const userProjectList = user.value?.navigationSort?.length ? sortedProject : globalStore.projectList
          const myProjects: { menuName: string; menuIcon: string; children: any }[] = []
          if (isLogin.value && userProjectList.length) {
            userProjectList?.forEach((item) => {
              const secondaryMenu: any = []
              const projectRole = find(user.value?.entityMemberList, { projectId: item.projectId })?.role
              if (item?.entityList && item?.entityList?.length) {
                const entityList = item.entityList.filter((entity: any) => {
                  const permission = find(user.value?.entityMemberList, { entityId: entity.entityId })?.permission ?? {}
                  // 过滤permission对象中的字段都为false的情况
                  return Object.values(permission).some((value) => value)
                })
                entityList?.forEach((entity: any) => {
                  const entityMember = find(user.value?.entityMemberList, {
                    entityId: entity.entityId
                  })
                  const entityPermission = entityMember?.permission
                  const role = entityMember?.role
                  const thirdLevelMenu: any = []
                  const addMenuItem = (permission: boolean, url: string, id: string, name: string) => {
                    if (permission || !['MEMBER', ''].includes(role || '')) {
                      thirdLevelMenu.push({
                        jumpUrl: `/entity/${entity.entityId}/${url}`,
                        id: `${entity.name}${entity.projectId}${entity.entityId}${id}`,
                        projectName: item.name,
                        entityName: entity.name,
                        projectId: entity.projectId,
                        entityId: entity.entityId,
                        requiresAuth: true,
                        line: false,
                        soon: false,
                        name: $t(name),
                        menuName: entity.name // 每一项添加其父级‘menuName’
                      })
                    }
                  }
                  addMenuItem(entityPermission?.dashboardView ?? false, 'dashboard', 'Dashboard', 'menus.dashboard')
                  addMenuItem(
                    entityPermission?.entityAccountView ?? false,
                    'accounts',
                    'Accounts',
                    'project.nav.accounts'
                  )
                  addMenuItem(
                    entityPermission?.transactionViewList || false,
                    'transactions',
                    'Transactions',
                    'menus.transactions'
                  )
                  if (item.plan !== 'OLD_BASIC') {
                    addMenuItem(entityPermission?.valuationView ?? false, 'valuation', 'Valuation', 'menus.valuation')
                  }
                  addMenuItem(
                    (entityPermission?.generalLedgerView || entityPermission?.journalViewList) ?? false,
                    'ledger',
                    'Ledger',
                    'menus.ledger'
                  )
                  if (item.plan !== 'OLD_BASIC') {
                    addMenuItem(
                      entityPermission?.transactionViewList ?? false,
                      'reconciliation',
                      'Reconciliation',
                      'report.reconciliation'
                    )
                  }
                  addMenuItem(entityPermission?.reportView ?? false, 'reports', 'Reports', 'menus.reports')

                  const secondary = {
                    projectId: entity.projectId,
                    entityId: entity.entityId,
                    menuName: entity.name,
                    children: thirdLevelMenu
                  }
                  secondaryMenu.push(secondary)
                })
                const project = {
                  menuName: item.name,
                  menuIcon: 'menu-my-projects',
                  plan: item.plan,
                  projectId: item.projectId,
                  role: projectRole,
                  userId: String(item.userId),
                  children: secondaryMenu
                }

                myProjects.push(project)
              } else if (!item?.entityList?.length || !item?.entityList) {
                const project = {
                  menuName: item.name,
                  menuIcon: 'menu-my-projects',
                  plan: item.plan,
                  projectId: item.projectId,
                  role: projectRole,
                  userId: String(item.userId),
                  children: []
                }
                myProjects.push(project)
              }
            })
          }
          this.menuList = myProjects
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }
  },
  getters: {
    headerMainDataAll() {
      const mainDataAll: HeaderMenuMainDataItemType[] = this.menuList.map((item: MenuItemType) => {
        const childrenNoSoon = item.children.filter((itemChildren: NavigationType) => {
          return itemChildren.soon !== true
        })
        const itemVal = {
          ...item,
          childrenNum: item.children.length,
          childrenNoSoon
        }
        return itemVal
      })
      const data = { mainDataAll }
      return data
    }
  }
})
