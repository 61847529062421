import { http } from '@/lib/http'
import { ConfigType } from '#/GlobalConfigTypes'
import { CurrencyItemType } from '#/ReportsTypes'

export default {
  /**
   * 获取配置
   */
  getGlobalConfig: () => {
    return http.request<ResponseData<ConfigType>>('get', `/config`)
  },
  /**
   * 可搜索的Currency列表
   * @param {Object} params
   * @param {string|number} params.entityId 项目id
   * @param {boolean} params.recommend 是否推荐
   * @param {string[]} [params.keywords] symbol关键字
   * @param {boolean} [params.isPriceProvider] 是否返回自定义Asset含有价格源的币种
   * @param {string} [params.type] CRYPTO/FIAT 币种类型
   * @param {string} [params.name] 币种名称搜索
   */
  searchCurrencyList: (params: object) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/underlyingCurrency`, { params })
  }
}
