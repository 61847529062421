<template>
  <el-button class="elv-button" v-bind="$attrs" :props="$attrs">
    <template v-for="(_, name) in $slots" #[name]="scope">
      <slot :name="name" v-bind="scope || {}" />
    </template>
  </el-button>
</template>
<script setup lang="ts">
defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  width: {
    type: [String, Number],
    default: ''
  },
  height: {
    type: [String, Number],
    default: '36'
  }
})

const buttonWidth = computed(() => {
  if (!props.width) return 'auto'
  if (!Number(props.width)) return props.width
  return `${props.width}px`
})

const buttonHeight = computed(() => {
  if (!Number(props.height)) return props.height
  return `${props.height}px`
})
</script>

<style lang="scss" scoped>
.elv-button {
  width: v-bind('buttonWidth');
  height: v-bind('buttonHeight');
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #0e0f11;
  border-radius: 4px;

  &.el-button--primary {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    background: #1753eb;
    border: 1px solid transparent;
    box-shadow:
      0 2px 3px rgb(44 49 64 / 10%),
      0 0 1px #0048ff;

    &:not(.is-disabled, .is-plain):focus,
    &:not(.is-disabled, .is-plain):hover {
      color: #fff;
      background: #2f63eb;
      box-shadow:
        0 2px 3px rgb(44 49 64 / 10%),
        0 0 1px #0048ff;
    }

    &:not(.is-disabled, .is-plain):active {
      color: #fff;
      background: #1343bf;
      box-shadow:
        0 2px 3px rgb(44 49 64 / 10%),
        0 0 1px #0048ff;
    }
  }

  &.el-button--info {
    font-family: 'PingFang SC';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #636b75;
    background: #edf0f3;
    border: 1px solid transparent;

    &:not(.is-disabled, .is-plain):focus,
    &:not(.is-disabled, .is-plain):hover {
      color: #636b75;
      background: #edf0f3;
    }

    &:not(.is-disabled, .is-plain):active {
      color: #636b75;
      background: #edf0f3;
    }
  }

  :deep(:not(.el-icon) svg) {
    margin-right: 7px;
    transition: all 0.1s;
  }

  &.is-round {
    border-radius: 30px;
  }

  &:not(.is-disabled, .is-plain, .el-button--info):focus,
  &:not(.is-disabled, .is-plain, .el-button--info):hover {
    color: #0e0f11;
    background: #fff;
    border: 1px solid #5e85eb;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
  }

  &:not(.is-disabled, .is-plain, .el-button--info):active {
    color: #0e0f11;
    background: #fff;
    border: 1px solid #1753eb;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
  }

  &.is-plain {
    font-weight: 500;
    color: #1e2024;
    background-color: #fff;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 4%));
    border: 1px solid #dde1e6;
    // border-radius: 24px;
    box-shadow: none;

    &:not(.is-disabled):deep(svg) {
      fill: #1e2024;
    }

    &:not(.is-disabled):focus,
    &:not(.is-disabled):hover {
      color: #1343bf;
      border: 1px solid #5e85eb;

      :deep(svg) {
        fill: #1343bf;
      }
    }

    &.el-button--primary {
      color: #1753eb;
      background: #ffff;
      border: 1px solid #edf0f3;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 4%);

      &:not(.is-disabled):deep(svg) {
        fill: #1753eb;
      }

      &:not(.is-disabled, .is-plain):focus,
      &:not(.is-disabled, .is-plain):hover {
        border: 1px solid #5e85eb;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 4%);

        :deep(svg) {
          fill: #1343bf;
        }
      }

      &:not(.is-disabled, .is-plain):active {
        border: 1px solid #5e85eb;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 4%);

        :deep(svg) {
          fill: #1343bf;
        }
      }
    }
  }

  &.is-disabled {
    color: #838d95 !important;
    background: #edf0f3 !important;
    box-shadow:
      0 2px 3px rgb(44 49 64 / 10%),
      0 0 1px #a3a3a3 !important;
  }

  .elv-button__loading {
    display: block;
    width: 24px;
    height: 24px;
    // margin-right: 8px;
    animation: elv-loading-rotate 2s linear infinite;
  }
}
@keyframes elv-loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
