<template>
  <el-pagination
    ref="elPaginationRef"
    class="elv-pagination"
    background
    :page-sizes="props.pageSizes"
    layout="slot, prev, pager, next"
    :hide-on-single-page="props.hideOnSinglePage"
    :prev-icon="PrevIcon"
    :next-icon="NextIcon"
    v-bind="$attrs"
    :page-count="pageCount"
  >
    <!-- :page-sizes="[20, 50, 100, 200]" -->
    <div key="1" class="elv-pagination-operating">
      <span class="elv-pagination__total">{{ t('common.total') }}: {{ formatNumber($attrs?.total) }}</span>
      <el-select
        :popper-class="props.sizePopperClass"
        :model-value="props.limit"
        :suffix-icon="SuffixIcon"
        class="elv-pagination__sizes"
        @change="onChangePageSize"
      >
        <template #prefix
          >{{ props.limit }} <span>/{{ t('common.page') }}</span></template
        >
        <el-option v-for="(pageSize, index) in props.pageSizes" :key="index" :value="pageSize">
          <template #default
            >{{ pageSize }} <span>/{{ t('common.page') }}</span></template
          >
        </el-option>
      </el-select>
    </div>
  </el-pagination>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { formatNumber } from '@/lib/utils'
import NextIcon from './components/NextIcon.vue'
import PrevIcon from './components/PrevIcon.vue'
import SuffixIcon from './components/SelectSuffixIcon.vue'

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  limit: {
    type: Number,
    required: true
  },
  sizePopperClass: {
    type: String,
    default: 'elv-pagination__sizes-popper'
  },
  hideOnSinglePage: {
    type: Boolean,
    default: false
  },
  pageSizes: {
    type: Array as () => number[],
    default: () => [20, 50, 100]
  }
})

const emit = defineEmits(['sizeChange'])

const { t } = useI18n()
const attrs: any = useAttrs()

const elPaginationRef = ref()

const pageCount = computed(() => {
  return Math.ceil(attrs.total / props.limit)
})

const onChangePageSize = (limit: number) => {
  emit('sizeChange', limit)
}
</script>

<style lang="scss">
.elv-pagination {
  margin-top: 16px;
  justify-content: flex-end;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
  position: relative;

  &.el-pagination.is-background {
    .el-pager {
      li {
        height: 26px;
        min-width: 26px;
        border: 1px solid #edf0f3;
        background-color: #fff;
        font-family: 'Barlow';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        border-radius: 3px;
        color: #1e2024;

        &:not(.is-active):hover {
          color: #1753eb;
          border-color: #7596eb;
        }

        &:not(.is-active):active {
          border: 0px;
          color: #fff;
          font-weight: 700;
          background-color: #1343bf;
        }

        &.is-active {
          border: 0px;
          color: #fff;
          font-weight: 700;
          background-color: #1753eb;
        }

        &.more {
          border: 0px;
        }
      }
    }

    .elv-pagination-operating {
      position: absolute;
      left: 20px;
      display: flex;
      align-items: center;

      justify-content: center;

      .elv-pagination__total {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .elv-pagination__sizes {
        margin-left: 16px;
        height: 26px;

        &.el-select {
          .el-select__wrapper {
            padding: 1px 8px;
            height: 26px;
            min-height: 26px;
            box-sizing: border-box;
            width: fit-content;

            .el-select__prefix {
              color: #1e2024;
              font-family: 'Barlow';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              span {
                color: #838d95;
                margin-left: 4px;
              }
            }
          }
        }

        .el-icon.el-select__icon {
          margin-left: 0px;
        }
      }
    }

    .btn-prev,
    .btn-next {
      width: 46px;
      height: 26px;
      padding: 6px 16px 6px 16px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #edf0f3;

      svg {
        fill: #1e2024;
      }

      &:not(:disabled):hover {
        color: #1753eb;
        border-color: #7596eb;

        svg {
          fill: #1753eb;
        }
      }

      &:disabled {
        color: #a8abb2;
        border-color: #edf0f3;

        svg {
          fill: #aaafb6;
        }
      }
    }
  }
}
</style>
